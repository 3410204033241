import '/src/scss/style.scss';
import 'vidstack/player/styles/default/theme.css';
import 'vidstack/player/styles/default/layouts/video.css';

if (import.meta.hot) {
	import.meta.hot.accept();
}

import { loadApp, loadModule } from '@spon/core';
import 'lazysizes';
import webfontloader from '@/plugins/webfontloader';
import FathomTracking from './behaviours/FathomTracking';

webfontloader();

// Load from data-behaviours dynamically
loadApp(name => import(`./behaviours/${name}.js`), document.body);

// Dynamic imports for Header and frequently used modules
let headerModule = null;

(async () => {
	headerModule = await loadModule({
		module: (await import('@/globals/Header')).default,
		id: 'header',
		node: document.getElementById('header'),
		keepAlive: true,
	});
})();

document.addEventListener('DOMContentLoaded', async () => {
	// Dynamically load modal and other components when DOM is ready
	await loadModule({
		module: (await import('@/globals/Modal')).default,
		id: 'modal',
		node: document.body,
		keepAlive: true,
	});

	await loadModule({
		module: (await import('@/globals/SetVh')).default,
		id: 'set-vh',
		node: document.body,
		keepAlive: true,
	});

	await loadModule({
		module: (await import('@/globals/FadeIn')).default,
		id: 'fadein',
		node: document.body,
		keepAlive: true,
	});

	loadModule({
		module: FathomTracking,
		id: 'fathomTracking',
		node: document.body,
		keepAlive: true,
	});
});

// Dynamically load non-critical plugins
document.addEventListener('afterBlitzInjectAll', async () => {
	if (!headerModule) {
		headerModule = await loadModule({
			module: (await import('@/globals/Header')).default,
			id: 'header',
			node: document.getElementById('header'),
			keepAlive: false,
		});
	}
});

// Lazy load and initialize utilities
(async () => {
	const { default: detectTab } = await import('@/utils/detectTab');
	detectTab();

	const { default: activeLinks } = await import('@/utils/activeLinks');
	activeLinks(window.location.pathname);

	const { default: localLinks } = await import('@/utils/localLinks');
	localLinks();
})();

// Enhance HTMX handling with dynamic imports
if (typeof htmx !== 'undefined') {
	htmx.on('htmx:beforeSend', ({ target }) => {
		const results = document.getElementById('results');
		if (results) {
			results.classList.add('is-loading');
		}
	});

	htmx.on(
		'htmx:afterSettle',
		async ({
			target,
			detail: {
				requestConfig: { triggeringEvent },
			},
		}) => {
			const { default: localLinks } = await import('@/utils/localLinks');
			localLinks(target);

			await loadApp(name => import(`./behaviours/${name}.js`), target);

			if (triggeringEvent) {
				const { srcElement } = triggeringEvent;
				if (!srcElement) return;

				const shouldScroll =
					srcElement.closest('.c-pagination') || srcElement.dataset.scroll;
				if (srcElement && shouldScroll) {
					window.scrollTo({
						top: 0,
						behavior: 'smooth',
					});
				}
			}
		}
	);
}
