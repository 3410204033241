
function FathomTracking({ node }) {
	
	if (typeof fathom === 'undefined') {
		console.warn('Fathom Analytics not found.')
		return
	}

	const contactLinks = node.querySelectorAll('a[href^="mailto:"], a[href^="tel:"]')

	contactLinks.forEach(item => {
		
		item.addEventListener('click', event => {
			const href = item.getAttribute('href')
			fathom.trackEvent(`Contact link clicked: ${href}`)
		})
		
	})
	
}

export default FathomTracking
